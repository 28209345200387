export const moduleConstant = {
    crudApi: '/api/masterListOfPFMEA',
    childHeader: 'Master List Of PFMEA',
    btnName: 'New PFMEA',
    searchLabel: 'Family Name or Document ID',
    moduleName: 'Master List of PFMEA',
  };
  
  export const tableHeaderObj = [
    [
      { text: '#', rowspan: 2, colspan: 1, style: { border: '1px solid black' } },
      {
        text: 'Family Name',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Document ID',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
  
      {
        text: 'Rev. No. & Date of Implementation',
        rowspan: 1,
        colspan: 22,
        style: { border: '1px solid black' },
      },
      {
        text: 'Action',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
    ],
    [
      {
        text: 'Initial Date',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: '1',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '2',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '3',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '4',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '5',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '6',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '7',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '8',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '9',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '10',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '11',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '12',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '13',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '14',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '15',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '16',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '17',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '18',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '19',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: '20',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black', minWidth: '120px' },
      },
      {
        text: 'Remarks',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
    ],
  ];
  