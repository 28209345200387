export const moduleConstant = {
    crudApi: '/api/masterListOfCutSection',
    childHeader: 'Master List Of Cut Section WI',
    btnName: 'New Cut Section',
    searchLabel: 'Part Name Or Number',
    moduleName: 'Master List of Cut Section',
  };
  
  export const tableHeaderObj = [
    [
      { text: '#', rowspan: 2, colspan: 1, style: { border: '1px solid black' } },
      {
        text: 'Part Name',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Part Number ',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Document No.',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
  
      {
        text: 'Rev. No. ',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Rev. Date',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Remarks',
        rowspan: 1,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Action',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
    ],
  ];
  