import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import {SearchBar} from '../../../components/common';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import { EditIconButton, DeleteIconButton } from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { Loader } from '../../../components/common/Loader';
import { moduleConstant } from './constant';
import { tableHeaderObj } from './constant';
import formatDate from '../../../components/common/formatDate';
import { useEffect } from 'react';


const ListofDrawing = () => {
  const [data, setData] = useState<any>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const search = useQuery('search');
  const filter = useQuery('filter') ;
  const page = useQuery('page') || 1;
  const customer = useQuery('customer');

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer?._id,
          }));
          setFilterCustomer([...formattedData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchData = async () => {
    const res = await axios
      .get(
        `${moduleConstant.crudApi}?page=${page}&sortBy=${filter}&search=${search}&customer=${customer}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const handleCustomerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected = event.target.value as string;
    setSelectedCustomer(selected); // Update the selected customer
    setRender((prev) => !prev); // Trigger a re-fetch of data
  };
  
  
  


  const deleteModuleData = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`${moduleConstant.crudApi}/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter, customer]);

  return (
    <>
      <ChildHeader text={`${moduleConstant?.childHeader}`}>
        <AddButton
          onClick={() => setIsModal(true)}
          label={`${moduleConstant?.btnName}`}
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label={`Search ${moduleConstant?.searchLabel}`} />
        <FilterBy
          sx={{ width: 500 }}
          name="customer"
          label="Filter By Customer"
          options={filterCustomer}
        />
      </SearchFilterContainer>
      {/* <SearchFilterContainer filter={true}>
        <FilterBy
          sx={{ width: 500 }}
          name="customer"
          label="Filter By Customer"
          options={filterCustomer}
        />
      </SearchFilterContainer> */}
      <Loader loader={isLoading} />
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.part_id?.part_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.part_id?.part_number}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.customer_id?.customer_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.rev_no}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.rev_date && formatDate(item?.rev_date)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.recd_date && formatDate(item?.recd_date)}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.controlled}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.uncontrolled}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.status}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.remarks}
                  </TableCell>
                  <TableCell style={{ border: '1px solid black' }}>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setModuleId(item?._id || '');
                          setIsModal(true);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteModuleData(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title={`${moduleConstant?.moduleName}`}
      >
        <Create id={moduleId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default ListofDrawing;
