export const moduleConstant = {
    crudApi: '/api/masterListofDrawing',
    childHeader: 'Master List Of Drawing',
    btnName: 'New Control Plan',
    searchLabel: 'Part Name Or Number',
    moduleName: 'Master List of Drawing',
  };
  
  export const tableHeaderObj = [
    [
      { text: '#', rowspan: 2, colspan: 1, style: { border: '1px solid black' } },
      {
        text: 'Part Name',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Part Number ',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Customer',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
  
      {
        text: 'Rev. No.',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Rev. Date',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Recd. Date',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Controlled',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Uncontrolled',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Status',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Remarks',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
      {
        text: 'Action',
        rowspan: 2,
        colspan: 1,
        style: { border: '1px solid black' },
      },
    ],
  ];
  