import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import { Grid, SelectChangeEvent } from '@mui/material';
import ModalCustom from '../../../components/common/Modal';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import { moduleConstant } from './constant';
import moment from 'moment';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    rev_no_and_date: [], // index + 1 is rev no and corresponding date
  });
  const [_, setRender] = useState(false);
  const [isPartModal, setIsPartModal] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? `Update ` : `Save`} ${moduleConstant?.moduleName}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`${moduleConstant?.crudApi}/${id}`)
        .then((res) => {
          setModuleData(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleMoooduleData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`${moduleConstant?.crudApi}/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`${moduleConstant?.crudApi}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'family_name'}
        name={'family_name'}
        html_for={'family_name'}
        label_name={'Family Name'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.family_name}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'doc_id'}
        name={'doc_id'}
        html_for={'doc_id'}
        label_name={'Document ID'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.doc_id}
        onChange={handleMoooduleData}
      />
      <DateGridMui
        lable={`Initial date`}
        value={
          (moduleData?.initial_date && moment(moduleData?.initial_date)) || null
        }
        onChange={(date: any) => {
          moduleData.initial_date = moment(date._d);
          setRender((prev) => !prev);
        }}
      />
      {[1, 2, 3, 4, 5, 6, 7, 8 ,9 ,10, 11, 12, 13, 14, 15, 16, 17, 18 ,19, 20].map((_, i) => (
        <DateGridMui
          lable={`REV NO & Date of Implementation ${i + 1}`}
          value={
            (moduleData?.rev_no_and_date[i] &&
              moment(moduleData?.rev_no_and_date[i])) ||
            null
          }
          onChange={(date: any) => {
            moduleData.rev_no_and_date[i] = moment(date._d);
            setRender((prev) => !prev);
          }}
        />
      ))}
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks}
        onChange={handleMoooduleData}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
