import { BsPersonWorkspace } from 'react-icons/bs';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { SiInstructure } from 'react-icons/si';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { TbAspectRatio } from 'react-icons/tb';

const tiles = [
  {
    sNo: 1,
    link: '/common/annualPmPlanUtilitylist',
    isLink: true,
    icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    p: 'ANNUAL PM',
    span: 'PLAN (UTILITY)',
    isIcon: true,
  },

  {
    sNo: 2,
    link: '/common/annualPmPlanUtilityChecklist',
    isLink: true,
    icon: <BsReverseListColumnsReverse size="5rem" color="#087f5b" />,
    p: 'ANNUAL PM',
    span: 'STATUS (UTILITY)',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/annualPmPlanUtility_Checklist',
    isLink: true,
    icon: <TbAspectRatio size="5rem" color="#343a40" />,
    p: 'Plan',
    span: 'Checklists',
    isIcon: true,
  },
  // {
  //   path: 'annualPmPlan_Checklist',
  //   element: <AnnualPmPlan_Checklist />,
  // },
  // {
  //   path: 'annualPmPlan_ChecklistCreate/:id?',
  //   element: <AnnualPmPlan_ChecklistCreate />,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/annualPmPlanRotorCastingMasterlist',
  //   isLink: true,
  //   icon: <SiInstructure size="5rem" color="#343a40" />,
  //   p: 'Master list of',
  //   span: 'Instrument',
  //   isIcon: true,
  // },
];

const Tiles = () => {
  return (
    <>
      <ChildHeader text="INSTRUMENTS MSA PLAN" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Tiles;
