import moment from 'moment';

export const processes = [
  {
    rejection_rec_from: null,
    part_id: null,
    defects: null,
    check_qty: null,
    rejection_qty: null,
    rejection_percentage: null,
    cause: null,
    rework: null,
    scrap: null,
    corrective_and_preventive_action: null,
    responsibility: null,
    target_date: moment(),
    status: null,
    redbin_approval: null,
    remarks: null,
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyProduction_date: moment(),
  team_members: null,
  shop_floor_members: null,
  team_members_sign: null,
  shop_floor_members_sign: null,
  processes: Array.from({ length: 10 }, () => ({ ...processes[0] })),
};
