import { AiOutlineDeliveredProcedure } from 'react-icons/ai';
import { BsFillPeopleFill } from 'react-icons/bs';
import { GrUser } from 'react-icons/gr';
import { MdComputer } from 'react-icons/md';
import { TbAlertTriangleFilled, TbNotebook } from 'react-icons/tb';
import { FaWarehouse } from "react-icons/fa";

const tiles = [
  {
    sNo: 1,
    link: '/common/handling_abnormility_listOfProcedures/?documentType=handling_abnormility&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURE',
    span: '',
    isIcon: true,
  },
  {
    sNo: 10,
    link: '/common/abnormalityLogBookList',
    isLink: true,
    icon: <TbNotebook size="5rem" color="#a12222" />,
    p: 'ABNORMALITY',
    span: 'LOG BOOK',
    isIcon: true,
  },
  {
    sNo: 11,
    link: `/common/customerComplaintRecords`,
    isLink: true,
    clauseNo: 8,
    icon: <GrUser size="5rem" color="#003566" />,
    p: 'CUSTOMER COMPLAINT',
    span: 'RECORDS',
    isIcon: true,
  },
  {
    sNo: 12,
    link: '/common/qualityAlert',
    isLink: true,
    clauseNo: 8,
    icon: <TbAlertTriangleFilled size="5rem" color="#135089" />,
    p: 'QUALITY',
    span: 'ALERT',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/customerList',
    isLink: true,
    icon: <BsFillPeopleFill size="5rem" color="#135089" />,
    p: 'CUSTOMER RATING',
    span: 'MODULE',
    isIcon: true,
  },
  {
    sNo: 13,
    link: `/common/inhouseComplaintRecords`,
    isLink: true,
    clauseNo: 8,
    icon: <FaWarehouse size="5rem" color="#003566" />,
    p: 'INHOUSE COMPLAINT',
    span: 'RECORDS',
    isIcon: true,
  },
];

export default tiles;
