import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { Loader } from '../../../components/common/Loader';

import { moduleConstant, tableHeaderObj } from './constant';
import formatDate from '../../../components/common/formatDate';

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];

const MasterListofProcessSheet = () => {
  const [data, setData] = useState<any>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const search = useQuery('search');
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `${moduleConstant.crudApi}?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteModuleData = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`${moduleConstant.crudApi}/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal]);

  return (
    <>
      <ChildHeader text={`${moduleConstant?.childHeader}`}>
        <AddButton
          onClick={() => setIsModal(true)}
          label={`${moduleConstant?.btnName}`}
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label={`Search ${moduleConstant?.searchLabel}`} />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <Loader loader={isLoading} />
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.part_id?.part_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.part_id?.part_number}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.doc_id}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.initial_date && formatDate(item?.initial_date)}
                  </TableCell>
                  {[1, 2, 3, 4, 5, 6, 7].map((_, i) => (
                    <TableCell
                      align="center"
                      style={{ border: '1px solid black' }}
                    >
                      {item?.rev_no_and_date?.[i] &&
                        formatDate(item?.rev_no_and_date?.[i])}
                    </TableCell>
                  ))}
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.remarks}
                  </TableCell>
                  <TableCell style={{ border: '1px solid black' }}>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setModuleId(item?._id || '');
                          setIsModal(true);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteModuleData(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title={`${moduleConstant?.moduleName}`}
      >
        <Create id={moduleId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default MasterListofProcessSheet;
