import React, { useState, useEffect } from 'react';
import { Stack, TableBody } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { StyledTableCell } from '../../clause5/helpers/module.styled';
import formatDate from '../../../components/common/formatDate';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { ViewIconButton } from '../../../components/common/button/CustomIconButton';
import { useRef } from 'react';
import ViewFile from './components/ViewFile';
import { StyledTableRow } from '../../../styled/styledTableRowAndCell';

const style1 = {
  backgroundColor: "#4F81BD",
  color: "#fff",
  border: "1px solid #343a40",
}; 

const tableHeaderObj = [
  [
    { text: "S.No.", rowspan: 1, colspan: 1, style:{style1}},
    { text: "DOCUMENT", rowspan: 1, colspan: 1, style:{style1} },
    { text: "DETAIL", rowspan: 1, colspan: 1, style:{style1}},
    { text: "TYPES OF DOCUMENTS", rowspan: 1, colspan: 1, style:{style1} },
    { text: "RECEIPT DATE", rowspan: 1, colspan: 1,style:{style1} },
    { text: "EFFECT DATE", rowspan: 1, colspan: 1,style:{style1} },
    {text: "UPLOAD FILE", rowspan: 1, colspan: 1,style:{style1}},
    { text: "REMARKS", rowspan: 1, colspan: 1,style:{style1} },
    { text: "ACTION", rowspan: 1, colspan: 1,style:{style1} },
  ],
];

interface RecordData {
  _id: string;
  document: string;
  detail: string;
  type: string;
  effect_date: string;
  receipt_date: string;
  remarks?: string;
  files: [ //Upload_File
    {
      name: String,
      url: String,
      key: String,
    },
  ];
}

const MasterListofRecord = () => {
  const limit = 10;
  const [data, setData] = useState<RecordData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [listId, setListId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const idRef = useRef('');




  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this record from the list?'
  );

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/api/masterListofRecord?page=${page}&search=${search}&limit=${limit}`
      );
      if (response.data) {
        setData(response.data.data.results);
        setTotalPages(response.data.data.totalPages);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, isOpenPopup]);
  
  const uploadFile = async (e: any, id: string) => {
    if (!window.confirm('Are you sure you want to upload this file? ')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);
    axios
      .patch(`/api/masterListofRecord/uploadFile/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('Document Uploaded Successfully');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };


  // const handleDelete = async (id: string) => {
  //   const confirmed = await confirmDelete();
  //   if (!confirmed) return;
    
  //   try {
  //     await axios.delete(`/api/toolReport/${id}`);
  //     alert('Tool report deleted successfully');
  //     fetchData();
  //   } catch (err) {
  //     console.error('Error deleting tool report:', err);
  //   }
  // }; 

  const deleteSupplier = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/masterListofRecord/${id}`)
      .then((res) => {
        if (res.data) {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <ChildHeader text="MASTER LIST OF RECORDS">
        <AddButton onClick={() => setIsModal(true)} label="NEW RECORD" />
      </ChildHeader>

      <SearchFilterContainer filter={false}>
        <SearchBar label="Search Records" />
      </SearchFilterContainer>

      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data.map((item: RecordData, index: number) => (
            <StyledTableRow key={item._id} index={index}>
              <StyledTableCell align="center">
                {perPage * (+page - 1) + index + 1}
              </StyledTableCell>
              <StyledTableCell align="center">
                {item.document}
              </StyledTableCell>
              <StyledTableCell align="center">
                {item.detail}
              </StyledTableCell>
              <StyledTableCell align="center">
                {item.type}
              </StyledTableCell>
              <StyledTableCell align="center">
                { formatDate(item.receipt_date)} 
              </StyledTableCell>
              <StyledTableCell align="center">
                { formatDate(item.effect_date)} 
              </StyledTableCell>
              <StyledTableCell>
              <Stack
                      direction={'row'}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CustomUploadButton
                        id={`upload-vendor-documents-${index}`}
                        accept="image/png,image/jpeg,image/jpg,application/pdf"
                        multiple={true}
                        onChange={(e) => {
                          uploadFile(e, item?._id || '');
                        }}
                      />
                      {item.files?.length > 0 && (
                        <>
                          <ViewIconButton
                            tooltipTitle="view"
                            sx={{ marginLeft: '1.5rem' }}
                            onClick={() => {
                              idRef.current = item._id || '';
                              setIsOpenPopup(true);
                            }}
                          />
                        </>
                      )}

                      </Stack>
              </StyledTableCell>
              
              <StyledTableCell align="center">
                {item.remarks || '-'}
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" sx={{ justifyContent: 'center' }}>
                  <EditIconButton
                    tooltipTitle="EDIT"
                    onClick={() => {
                      setListId(item._id);
                      setIsModal(true);
                    }}
                  />
                  <DeleteIconButton
                    tooltipTitle="DELETE"
                    onClick={() => deleteSupplier(item._id || '')}
                  />
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </TableCustom>

      <CustomPagination totalPage={totalPages} />

      <ModalCustom
        title="FILE LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <ViewFile id={idRef.current} />
      </ModalCustom>

      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setListId(null);
          setIsModal(false);
        }}
        title={listId ? "EDIT RECORD" : "CREATE NEW RECORD"}
      >
        <Create 
          id={listId} 
          setIsModal={setIsModal} 
          setListId={setListId}
        />
      </ModalCustom>
    
      <DialogDelete />
    </>
  );
};

export default MasterListofRecord;