import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';
import moment from 'moment';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { useQuery } from '../../../hooks/UseQuery';

interface InstructionData {
    instruction?: string;
    doc_no?: string;
    rev_no?: string;
    rev_date?: string;
    remarks?: string; // optional field
  }


const Create = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [data, setData] = useState<InstructionData>({});
  const [supplierData, setSupplierData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Work Instruction' : 'Create Work Instruction'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this work instruction?`
  );
  
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterListofWorkInstruction/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);


  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: InstructionData) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterListofWorkInstruction/${id}`, data)
        .then((res) => {
          setIsSubmit(false);
          setListId(null);
          setIsModal(false);
          if (res.data) {
            alert("Document updated successfully");
            setRender((prev) => !prev);
          }
        })
        
          
        
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterListofWorkInstruction', data)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId(null);
          if (res.data) {
            alert("Document created successfully");
            setRender((prev) => !prev);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };
  return (
    <>
      <GridInputs
        id={'instruction'}
        name={'instruction'}
        html_for={'instruction'}
        label_name={'WORK INSTRUCTION NAME'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.instruction|| ''}
        onChange={handleData}
      />
      <GridInputs
        id={'doc_no'}
        name={'doc_no'}
        html_for={'doc_no'}
        label_name={'DOC. NO. :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.doc_no || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'rev_no'}
        name={'rev_no'}
        html_for={'rev_no'}
        label_name={'REV. NO. :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.rev_no || ''}
        onChange={handleData}
      />
      <GridDatePicker
        last_child={2}
        label_name="REV. DATE:"
        html_for={'REV. DATE'}
        value={data?.rev_date || null}
        onChange={(date) => {
            setData((prev) => ({
              ...prev,
              rev_date: moment(date).format('YYYY-MM-DD'),
            }));
          }}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'REMARKS:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
