export const listConstant = {
  childHeader: 'Annual PM Plan (UTILITY)',
  addButtonLable: 'New Annual PM Plan (UTILITY) ',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW Annual PLAN (UTILITY)',
};

export const createConstant = {
  childHeader: 'Annual PM Plan list (UTILITY)',
  theadHeader: 'Annual PM Plan (UTILITY)',
  extracolSpan: 4,
};

export const checkListList = {
  childHeader: 'Annual PM Plan STATUS (UTILITY)',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'annualpmplanutilityRoute',
  gettableDataurl: 'annualpmplanutilityRoute/monthly',
  getPlanByid: 'annualpmplanutilityRoute',
  createPlanByid: 'annualpmplanutilityRoute',
  updatePlanByid: 'annualpmplanutilityRoute',
  sync: 'annualpmplanutilityRoute/handleSync',
  getAllCheckList: 'annualpmplanutilityRoute/getAllCheckList',
  checkListStatus: 'annualpmplanutilityRoute/handleStatus',
  checklistUpload: 'annualpmplanutilityRoute/handleUpload',
  checklistDeleteImage: 'annualpmplanutilityRoute/handleDeleteImage',
  utilityDataForAutoComplete: 'masterlistofAppearance/utility/ForAutocomplete',

};

export const navigationConstant = {
  createPlanSchedule: '/common/annualPmPlanUtilityCreate',
};
