import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { CiViewList } from 'react-icons/ci';
import { GiBoxUnpacking } from 'react-icons/gi';
import { FaCut } from "react-icons/fa";
import { LuFileSpreadsheet } from "react-icons/lu";

const tiles = [
  {
    sNo: 1,
    link: '/common/home/masterList/pfd',
    isLink: true,
    icon: <CiViewList size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'PFD',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/home/masterList/pkgStd',
    isLink: true,
    icon: <GiBoxUnpacking size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Packing Standard',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/home/masterList/CutSection',
    isLink: true,
    icon: <FaCut size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Cut Section',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/home/masterList/PFMEA',
    isLink: true,
    icon: <CiViewList size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'PFMEA',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/home/masterList/ProcessSheet',
    isLink: true,
    icon: <LuFileSpreadsheet size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Process Sheet',
    isIcon: true,
  },
];

const QualityDeparmentHome = () => {
  return (
    <>
      <ChildHeader text="Master List" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default QualityDeparmentHome;
